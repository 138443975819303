import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { 
  faVideo, 
  faUser, 
  faFlask 
} from '@fortawesome/free-solid-svg-icons';

export const aboutMeContent = {
    welcome: "Welcome! I am Sweta Bose, a dedicated Clinical Dietician with a passion for empowering individuals to achieve their health goals through personalized nutrition plans.",
    certifications: "With specialized certifications in Hypertension, Hypercholesterolemia, Diabetes Management, PCOD, PCOS, Menopause, Pediatric Nutrition, and Therapeutic Nutrition (Renal, Hepatic, COPD, Arthritis), I bring a comprehensive approach to health and wellness.",
    journey: "My journey in nutrition began with a commitment to making a difference in people's lives through evidence-based dietary interventions. Over the years, I have honed my skills as a Certified Gut Nutritionist and Diabetic Educator, integrating the latest research and practical insights into my practice.",
    mission: "Whether you are navigating complex health conditions or simply striving for optimal well-being, my goal is to provide you with personalized nutrition strategies that are both effective and sustainable. Each client receives tailored diet plans crafted to meet their unique needs, supported by regular follow-ups to ensure ongoing success and satisfaction.",
    invitation: "I invite you to embark on a journey towards better health with me. Together, we can make nutrition work for you.",
    consultation: "For consultations, including online and home visits, feel free to reach out through my social media channels or contact details provided."
  };
  
  export const onlinePlans = [
    {
      id: 1,
      title: "One Time Consultation",
      price: "₹750",
      features: [
        "Tailor made diet  plans based on your work timings,travel schedule, availability of ingredients, allergies.",
        "Blood report analysis (if required)",
        "No of follow up 2 (bi-weekly)",
      ],
      description: "Our Basic Plan is perfect for those who need a one-time consultation to kickstart their healthy eating journey. You'll receive a personalized diet plan tailored to your needs, along with weekly follow-ups to ensure you're on track.",
      image: "/assets/basic.jpeg",
    },
    {
      id: 2,
      title: "3 Months Package",
      price: "₹2000",
      features: [
        "Tailor made diet  plans based on your work timings, travel schedule, availability of ingredients, allergies.",
        "Blood report analysis (if required)",
        "Weekly follow up" ,
        "Email support",
      ],
      description: "The Plus Plan is designed for individuals who require ongoing support. With this plan, you'll receive tailor-made diet plans each month, weekly follow-ups, and email support to answer any of your queries.",
      image: "/assets/plus.jpeg",
    },
    {
      id: 3,
      title: "6 Months Package",
      price: "₹4000",
      features: [
        "Tailor made diet  plans based on your work timings, travel schedule, availability of ingredients, allergies.",
        "Blood report analysis (if required)",
        "Weekly follow up with queries ",
        "Email and phone support",
        "Monthly video call",
      ],
      description: "Our Premium Plan offers comprehensive support for those who want to make a significant lifestyle change. This plan includes weekly follow-ups, personalized diet plans, email and phone support, and monthly video consultations for in-depth guidance.",
      image: "/assets/premium.jpeg",
    },
  ];
  
  export const homeVisitPlans = [
    {
      id: 4,
      title: "One Time Consultation",
      price: "₹1000",
      features: [
        "Tailor made diet  plans based on your work timings, travel schedule, availability of ingredients, allergies.",
        "Blood report analysis (if required)",
        "No of follow up 2 (bi-weekly over phone)",
      ],
      description: "The Home Visit Basic Plan provides a one-time consultation at your home, along with a personalized diet plan and weekly follow-ups to ensure you're achieving your health goals.",
      image: "/assets/basic.jpeg",
    },
    {
      id: 5,
      title: "3 Months Package",
      price: "₹2500",
      features: [
        "Tailor made diet  plans based on your work timings, travel schedule, availability of ingredients, allergies.",
        "Blood report analysis (if required)",
        "Weekly follow up (phone)",
        "Email support ",
      ],
      description: "Our Home Visit Plus Plan includes bi-weekly home visits for personalized consultations, along with monthly diet plans, weekly follow-ups, and email support to keep you on track.",
      image: "/assets/plus.jpeg",
    },
    {
      id: 6,
      title: "6 Months Package",
      price: "₹5000",
      features: [
        "Tailor made diet  plans based on your work timings, travel schedule, availability of ingredients, allergies.",
        "Blood report analysis (if required)",
        "Weekly follow up with queries (phone)",
        "Email and phone support",
        "Monthly video call",
      ],
      description: "The Home Visit Premium Plan offers the highest level of support with weekly home visits, monthly diet plans, and comprehensive email and phone support. You'll also receive monthly video consultations for an in-depth review of your progress.",
      image: "/assets/premium.jpeg",
    },
  ];
  

export const DURATIONS = {
  '1-month': { label: '1 Month', multiplier: 1 },
  '3-months': { label: '3 Months', multiplier: 3 },
  '6-months': { label: '6 Months', multiplier: 6 },
};

export const DEFAULT_DURATION = '1-month';



  export const blogDataArray = [
  {
    id: 1,
    category: 'Nutrition',
    date: '2023-07-25',
    headerImage: "/path/to/benefits-image.jpg",
    title: "The Power of Nutrient-Rich Foods: A Guide to Healthier Living",
    introduction: "In today's fast-paced world, maintaining a balanced diet can often be a challenge. However, incorporating nutrient-rich foods into your daily routine is essential for promoting overall health and well-being. As a nutritionist, I’m here to guide you on how to make smarter food choices that nourish your body and mind.",
    content: [
      {
        heading: "What Are Nutrient-Rich Foods?",
        text: "Nutrient-rich foods are those that provide a high amount of vitamins, minerals, and other essential nutrients relative to their calorie content. These foods are crucial for maintaining energy levels, supporting bodily functions, and preventing chronic diseases.",
      },
      {
        heading: "Benefits of Nutrient-Rich Foods",
        text: "1. Boosted Immunity: Foods high in vitamins A, C, and E, as well as zinc and selenium, help strengthen your immune system.\n2. Improved Digestion: Fiber-rich foods aid in digestion and help prevent constipation.\n3. Enhanced Energy Levels: Nutrient-dense foods provide sustained energy throughout the day, preventing the dreaded energy slump.\n4. Better Mental Health: Certain nutrients, like omega-3 fatty acids, are essential for brain health and can improve mood and cognitive function.\n5. Weight Management: These foods are often lower in calories but high in essential nutrients, making them ideal for maintaining a healthy weight.",
        image: "/path/to/benefits-image.jpg"
      },
      {
        heading: "Top Nutrient-Rich Foods to Include in Your Diet",
        text: "1. Leafy Greens: Spinach, kale, and Swiss chard are packed with vitamins A, C, and K, as well as iron and calcium.\n2. Berries: Blueberries, strawberries, and raspberries are rich in antioxidants, vitamins, and fiber.\n3. Nuts and Seeds: Almonds, chia seeds, and flaxseeds provide healthy fats, protein, and fiber.\n4. Fish: Salmon, mackerel, and sardines are excellent sources of omega-3 fatty acids.\n5. Whole Grains: Quinoa, brown rice, and oats are high in fiber, vitamins, and minerals.\n6. Legumes: Lentils, chickpeas, and black beans are great sources of plant-based protein and fiber.\n7. Fruits and Vegetables: Carrots, sweet potatoes, oranges, and bell peppers are rich in vitamins and minerals.",
        image: "/path/to/foods-image.jpg"
      },
      {
        heading: "Tips for Incorporating Nutrient-Rich Foods into Your Diet",
        text: "1. Plan Your Meals: Create a weekly meal plan that includes a variety of nutrient-dense foods.\n2. Shop Smart: Make a grocery list focusing on whole foods and avoid processed items.\n3. Prep Ahead: Prepare meals and snacks in advance to ensure you have healthy options readily available.\n4. Experiment with Recipes: Try new recipes that incorporate nutrient-rich ingredients.\n5. Stay Hydrated: Drink plenty of water to support digestion and overall health.",
      }
    ],
    mealPlan: [
      { name: "Breakfast", description: "Smoothie with spinach, berries, chia seeds, and almond milk." },
      { name: "Lunch", description: "Quinoa salad with mixed greens, cherry tomatoes, cucumbers, and grilled chicken." },
      { name: "Snack", description: "Apple slices with almond butter." },
      { name: "Dinner", description: "Baked salmon with sweet potato and steamed broccoli." },
      { name: "Dessert", description: "Greek yogurt with honey and walnuts." }
    ]
  },
  {
    id: 2,
    category: 'Nutrition',
    date: '2023-07-25',
    headerImage: "/path/to/benefits-image.jpg",
    title: "How Much Water You Should Drink a Day",
    introduction: "Staying hydrated is essential for maintaining good health. Water plays a vital role in various bodily functions, and knowing how much water you should drink each day can help you stay energized and healthy. Here’s a comprehensive guide to understanding your daily water needs.",
    content: [
      {
        heading: "Why Water is Important",
        text: "Water is crucial for various bodily functions, including digestion, circulation, temperature regulation, and nutrient absorption. It helps maintain the balance of bodily fluids, which affects overall health and well-being.",
        image: "https://example.com/images/importance-of-water.jpg"
      },
      {
        heading: "Factors Influencing Water Needs",
        text: "Several factors determine how much water you need, including age, gender, weight, activity level, and climate. For instance, people who exercise regularly or live in hot climates may need more water to stay hydrated.",
        image: "https://example.com/images/factors-influencing-water-needs.jpg"
      },
      {
        heading: "General Guidelines",
        text: "While individual water needs vary, a general guideline is to drink about 8 glasses (64 ounces) of water a day. However, some health experts recommend a more tailored approach, suggesting you drink half your body weight in ounces. For example, if you weigh 150 pounds, you should aim to drink 75 ounces of water per day.",
        image: "https://example.com/images/general-guidelines.jpg"
      },
      {
        heading: "Signs of Dehydration",
        text: "It's essential to recognize the signs of dehydration, which include dry mouth, fatigue, dizziness, dark urine, and less frequent urination. If you experience any of these symptoms, increase your water intake immediately.",
        image: "https://example.com/images/signs-of-dehydration.jpg"
      },
      {
        heading: "Tips to Stay Hydrated",
        text: "To ensure you stay adequately hydrated, carry a water bottle with you, set reminders to drink water throughout the day, and include water-rich foods in your diet like fruits and vegetables. Herbal teas and infused water can also be great alternatives to plain water.",
        image: "https://example.com/images/tips-to-stay-hydrated.jpg"
      }
    ],
    mealPlan: [
      {
        name: "Morning Hydration",
        description: "Start your day with a glass of water to kickstart your metabolism and rehydrate after a night's sleep."
      },
      {
        name: "Mid-Morning Hydration",
        description: "Drink a glass of water or herbal tea."
      },
      {
        name: "Lunch",
        description: "Have a glass of water with your meal and include water-rich foods like salads and fruits."
      },
      {
        name: "Afternoon Hydration",
        description: "Drink another glass of water or infused water."
      },
      {
        name: "Dinner",
        description: "Include a glass of water with your dinner and hydrate with water-rich foods like vegetables."
      },
      {
        name: "Evening Hydration",
        description: "End your day with a glass of water to stay hydrated overnight."
      }
    ]
  },
  {
    id: 3,
    category: 'Nutrition',
    date: '2023-07-25',
    headerImage: "/path/to/benefits-image.jpg",
    title: "Everything You Need to Know About the Ketogenic Diet",
    introduction: "The ketogenic diet has gained immense popularity for its potential health benefits and weight loss effects. This comprehensive guide covers everything you need to know about the ketogenic diet, from its basic principles to the benefits and potential risks.",
    content: [
      {
        heading: "What is the Ketogenic Diet?",
        text: "The ketogenic diet, often referred to as the keto diet, is a high-fat, low-carbohydrate diet designed to put your body into a state of ketosis. In ketosis, your body becomes incredibly efficient at burning fat for energy instead of carbohydrates.",
        image: "https://example.com/images/what-is-keto.jpg"
      },
      {
        heading: "How Does the Ketogenic Diet Work?",
        text: "By significantly reducing carbohydrate intake and replacing it with fat, the keto diet lowers insulin levels and shifts the body's metabolism away from carbs towards fats and ketones, which are produced in the liver from fat.",
        image: "https://example.com/images/how-keto-works.jpg"
      },
      {
        heading: "Benefits of the Ketogenic Diet",
        text: "The keto diet offers various benefits, including weight loss, improved mental clarity, increased energy levels, and better blood sugar control. It has also been linked to reduced risk factors for heart disease and improved cholesterol levels.",
        image: "https://example.com/images/keto-benefits.jpg"
      },
      {
        heading: "Potential Risks and Considerations",
        text: "While the keto diet can be beneficial, it's not for everyone. Some people may experience side effects such as the 'keto flu,' nutrient deficiencies, or digestive issues. It's important to consult with a healthcare provider before starting the diet.",
        image: "https://example.com/images/keto-risks.jpg"
      },
      {
        heading: "What to Eat on a Ketogenic Diet",
        text: "Foods to eat on a keto diet include meat, fatty fish, eggs, butter, nuts, healthy oils, avocados, and low-carb vegetables. Avoid sugary foods, grains, most fruits, and starchy vegetables.",
        image: "https://example.com/images/keto-foods.jpg"
      }
    ],
    mealPlan: [
      {
        name: "Breakfast",
        description: "Scrambled eggs with spinach and avocado."
      },
      {
        name: "Mid-Morning Snack",
        description: "A handful of almonds."
      },
      {
        name: "Lunch",
        description: "Grilled chicken salad with mixed greens, olive oil, and avocado."
      },
      {
        name: "Afternoon Snack",
        description: "Celery sticks with almond butter."
      },
      {
        name: "Dinner",
        description: "Salmon cooked in butter with a side of roasted broccoli."
      },
      {
        name: "Evening Snack",
        description: "Cheese slices or a small handful of macadamia nuts."
      }
    ]
  },
  {
    id: 4,
    category: 'Nutrition',
    date: '2023-07-25',
    headerImage: "/path/to/benefits-image.jpg",
    title: "5 Steps for Making Healthy and Delicious Salads",
    introduction: "Salads can be a nutritious and satisfying meal when made right. They are versatile, easy to prepare, and can be packed with various nutrients. Here are five steps to help you make healthy and delicious salads that you’ll love.",
    content: [
      {
        heading: "Step 1: Start with a Base of Fresh Greens",
        text: "The foundation of any great salad is fresh greens. Choose a variety of leafy greens such as spinach, kale, arugula, romaine, or mixed greens. These greens are rich in vitamins, minerals, and fiber, making them a nutritious base for your salad.",
        image: "https://example.com/images/fresh-greens.jpg"
      },
      {
        heading: "Step 2: Add Colorful Vegetables",
        text: "Incorporate a rainbow of vegetables to make your salad more appealing and nutrient-dense. Include vegetables like bell peppers, carrots, tomatoes, cucumbers, radishes, and beets. The more colorful your salad, the more vitamins and antioxidants it will contain.",
        image: "https://example.com/images/colorful-vegetables.jpg"
      },
      {
        heading: "Step 3: Include a Source of Protein",
        text: "Adding protein to your salad will make it more filling and nutritious. Opt for grilled chicken, boiled eggs, tofu, beans, lentils, or chickpeas. These proteins will help keep you satiated and provide essential nutrients for muscle repair and growth.",
        image: "https://example.com/images/salad-protein.jpg"
      },
      {
        heading: "Step 4: Top with Healthy Fats",
        text: "Healthy fats are important for absorbing fat-soluble vitamins and adding flavor to your salad. Include sources of healthy fats like avocado, nuts, seeds, or a drizzle of olive oil. These fats also add a satisfying texture to your salad.",
        image: "https://example.com/images/healthy-fats.jpg"
      },
      {
        heading: "Step 5: Dress It Up",
        text: "A delicious dressing can elevate your salad. Make your own dressing using ingredients like olive oil, balsamic vinegar, lemon juice, Dijon mustard, and herbs. Avoid store-bought dressings that are often high in sugar and preservatives.",
        image: "https://example.com/images/salad-dressing.jpg"
      }
    ],
    mealPlan: [
      {
        name: "Sample Salad Recipe",
        description: "Mixed greens, cherry tomatoes, cucumber, grilled chicken, avocado, sunflower seeds, and a homemade balsamic vinaigrette."
      }
    ]
  },
  {
    id: 5,
    category: "Nutrition",
    date: "2023-07-26",
    headerImage: "/path/to/healthy-diet-image.jpg",
    title: "How to Follow a Healthy Diet with a Tight Schedule",
    introduction: "Maintaining a healthy diet can be challenging when you have a busy schedule. However, with some planning and smart choices, you can eat well without spending too much time. Here are some practical tips to help you stay on track with your nutrition goals.",
    content: [
      {
        heading: "Tip 1: Plan Your Meals in Advance",
        text: "Taking a few minutes to plan your meals for the week can save you a lot of time and stress. Consider setting aside some time on the weekend to map out what you’ll eat for breakfast, lunch, and dinner. This way, you can ensure that you're incorporating a variety of nutrients into your diet.",
        image: "https://example.com/images/meal-planning.jpg"
      },
      {
        heading: "Tip 2: Prep Your Ingredients",
        text: "Meal prepping is a game-changer for busy individuals. Spend a couple of hours one day a week chopping vegetables, cooking grains, and portioning out snacks. This makes it easy to throw together quick, nutritious meals during the week. Prepping in bulk also ensures you have healthy options readily available.",
        image: "https://example.com/images/meal-prepping.jpg"
      },
      {
        heading: "Tip 3: Make Use of Leftovers",
        text: "Don't underestimate the power of leftovers. Cooking a larger portion of dinner can mean a ready-made lunch for the next day. Invest in good-quality storage containers to keep your meals fresh and easy to grab on the go.",
        image: "https://example.com/images/leftovers.jpg"
      },
      {
        heading: "Tip 4: Opt for Simple Recipes",
        text: "Healthy eating doesn't have to be complicated. Choose recipes that require minimal ingredients and can be prepared quickly. Stir-fries, salads, and sheet-pan meals are great options that are both nutritious and easy to make.",
        image: "https://example.com/images/simple-recipes.jpg"
      },
      {
        heading: "Tip 5: Stock Up on Healthy Snacks",
        text: "Keeping healthy snacks on hand can prevent you from reaching for less nutritious options when hunger strikes. Nuts, seeds, fruit, yogurt, and cut-up veggies are excellent choices that are easy to pack and eat anywhere.",
        image: "https://example.com/images/healthy-snacks.jpg"
      },
      {
        heading: "Tip 6: Stay Hydrated",
        text: "Drinking enough water is crucial for overall health. Carry a reusable water bottle with you and set reminders if you have trouble remembering to drink throughout the day. Sometimes, thirst is mistaken for hunger, so staying hydrated can also help with managing cravings.",
        image: "https://example.com/images/stay-hydrated.jpg"
      },
      {
        heading: "Tip 7: Use Technology to Your Advantage",
        text: "There are numerous apps and online resources available to help you stay organized and motivated. Use meal planning apps, grocery delivery services, and fitness trackers to streamline your routine and keep track of your progress.",
        image: "https://example.com/images/technology.jpg"
      },
      {
        heading: "Tip 8: Eat Mindfully",
        text: "Even with a busy schedule, take the time to eat mindfully. Avoid eating at your desk or in front of the TV. Instead, focus on your food and enjoy each bite. This practice can improve digestion and help you feel more satisfied.",
        image: "https://example.com/images/eat-mindfully.jpg"
      },
      {
        heading: "Tip 9: Don't Skip Meals",
        text: "Skipping meals can lead to overeating later in the day and negatively impact your energy levels. Aim to eat regular meals and snacks to keep your metabolism steady and prevent energy crashes.",
        image: "https://example.com/images/dont-skip-meals.jpg"
      },
      {
        heading: "Tip 10: Seek Professional Guidance",
        text: "If you're struggling to maintain a healthy diet, consider consulting with a nutritionist. They can provide personalized advice and meal plans tailored to your specific needs and schedule.",
        image: "https://example.com/images/nutritionist.jpg"
      }
    ],
    mealPlan: [
      {
        name: "Sample Quick Meal Plan",
        description: "Breakfast: Overnight oats with berries. Lunch: Quinoa salad with mixed greens, cherry tomatoes, cucumber, and grilled chicken. Snack: Apple slices with almond butter. Dinner: Stir-fry with tofu, bell peppers, broccoli, and brown rice."
      }
    ]
  },
  // Add more blog post objects here
];

export const serviceData = [
  {
    id: 1,
    title: "Cardiovascular Diet Management",
    description: "Our program for individuals navigating cardiovascular challenges with evidence-based nutritional strategies aimed at promoting heart health.",
    longDescription: "Our Cardiovascular Diet Management program is meticulously crafted for individuals navigating the challenges of hypertension, hypercholesterolemia, and other cardiovascular conditions. This comprehensive service is rooted in evidence-based nutritional strategies aimed at promoting heart health and mitigating risk factors associated with cardiovascular diseases. Our approach integrates heart-healthy meal plans, lifestyle modifications, and thorough education on reducing sodium intake, unhealthy fats, and cholesterol levels. We work closely with you to monitor your progress, adjust your diet as needed, and empower you with the knowledge to make sustainable lifestyle changes. Whether you are managing an existing condition or aiming to prevent future cardiovascular issues, our program is designed to support your journey to better heart health.",
    moreLongDescription: "The Cardiovascular Diet Management program provides a holistic approach to managing and improving heart health through a combination of personalized diet plans and lifestyle adjustments. We begin by assessing your current health status and cardiovascular risk factors, followed by the development of a tailored nutrition plan that emphasizes low-sodium, low-fat, and high-fiber foods. Education is a core component of our program, where we guide you on reading food labels, understanding the impact of different nutrients on heart health, and making healthier food choices. Regular follow-up appointments ensure that we track your progress, adjust your plan as needed, and address any challenges you may encounter. Our aim is to help you achieve and maintain optimal heart health through a comprehensive, supportive approach.",
    image: "/assets/Cardiovascular.png" 
  },
  {
    id: 2,
    title: "Diabetes Management",
    description: "Personalized nutrition plans for effective diabetes management, focusing on balanced meals, portion control, and continuous support.",
    longDescription: "Our Diabetes Management service is dedicated to providing personalized nutrition plans that address the unique needs of individuals with type 1, type 2, and gestational diabetes. Understanding that diabetes management extends beyond mere diet control, our program emphasizes maintaining optimal blood glucose levels through meticulously balanced meals, carbohydrate counting, and precise portion control. In addition to nutritional guidance, we offer continuous support, education, and monitoring to help prevent complications such as neuropathy, retinopathy, and cardiovascular disease. Our goal is to equip you with the tools, knowledge, and confidence to manage your diabetes effectively, ensuring a better quality of life while minimizing the impact of the disease.",
    moreLongDescription: "Our Diabetes Management program is designed to offer a comprehensive approach to managing diabetes through diet and lifestyle modifications. The process begins with a detailed assessment of your current health status and diabetes control, followed by the creation of a customized meal plan that balances carbohydrates, proteins, and fats to stabilize blood glucose levels. We provide in-depth education on carbohydrate counting, portion control, and the impact of various foods on blood sugar. Continuous monitoring and regular consultations help us to adjust your plan based on your progress and any changes in your health. Additionally, we offer strategies for dealing with common challenges such as insulin resistance, fluctuating blood sugar levels, and managing diabetes-related complications. Our program aims to empower you with the knowledge and skills needed for effective diabetes management and improved overall health.",
    image: "/assets/Diabetes.png"
  },
  {
    id: 3,
    title: "Weight Management",
    description: "Guidance on achieving and maintaining a healthy weight with personalized diet plans, physical activity, and behavioral strategies.",
    longDescription: "Our Weight Management service is designed to guide you towards achieving and maintaining a healthy weight, tailored to your specific goals—whether that be weight loss, weight gain, or maintenance. We provide a holistic approach that combines personalized diet plans with physical activity guidelines and behavioral strategies. Our team of nutrition experts will work with you to create a sustainable plan that not only helps you reach your desired weight but also supports long-term health and wellness. We focus on creating a balanced approach that considers your lifestyle, preferences, and any underlying health conditions, ensuring that your weight management journey is both effective and enjoyable.",
    moreLongDescription: "The Weight Management program begins with a thorough evaluation of your current weight, dietary habits, and lifestyle factors. Based on this assessment, we develop a customized weight management plan that includes a balanced diet, exercise recommendations, and behavioral strategies. Our approach is designed to help you achieve your weight goals in a sustainable way, addressing any obstacles or challenges you may face along the way. We offer ongoing support and guidance to help you stay motivated and on track, with regular check-ins to monitor your progress and make necessary adjustments. Additionally, we provide education on healthy eating habits, portion control, and the importance of physical activity in maintaining a healthy weight. Our goal is to support you in reaching your weight management objectives and promoting overall well-being.",
    image: "/assets/Weight.png"
  },
  {
    id: 4,
    title: "PCOD, PCOS, and Menopause Management",
    description: "Specialized programs for managing hormonal imbalances related to PCOD, PCOS, and menopause, with tailored dietary plans and support.",
    longDescription: "Our specialized programs for PCOD (Polycystic Ovarian Disease), PCOS (Polycystic Ovary Syndrome), and Menopause Management are dedicated to helping women navigate the complexities of hormonal imbalances. These conditions can significantly impact a woman’s quality of life, leading to challenges such as insulin resistance, inflammation, weight gain, and severe discomfort. Our program addresses these concerns with tailored dietary plans that focus on managing symptoms, improving metabolic health, and alleviating the effects of hormonal fluctuations. We provide comprehensive support that includes education, dietary adjustments, and lifestyle modifications to empower you with the tools to manage these conditions effectively and enhance your overall well-being.",
    moreLongDescription: "Our PCOD, PCOS, and Menopause Management program offers a targeted approach to address the unique needs of women dealing with these hormonal conditions. We start with a comprehensive assessment to understand your specific symptoms and health concerns. From there, we develop a personalized nutrition plan that aims to balance hormones, manage symptoms, and improve overall metabolic health. Our program includes education on dietary strategies to manage insulin resistance, reduce inflammation, and support weight management. We also provide guidance on lifestyle changes and stress management techniques that can help alleviate symptoms. Regular follow-up appointments ensure that we track your progress, make necessary adjustments, and offer ongoing support. Our goal is to help you achieve a better quality of life by effectively managing your hormonal health through tailored nutrition and lifestyle strategies.",
    image: "/assets/PCOD.png"
  },
  {
    id: 5,
    title: "Pediatric Nutrition Management",
    description: "Expert dietary guidance for children, ensuring proper nutrition for healthy growth and addressing common concerns like picky eating and food allergies.",
    longDescription: "Our Pediatric Nutrition Management service offers expert dietary guidance for children, from infancy through adolescence. We recognize the importance of proper nutrition in supporting a child’s healthy growth and development, addressing common concerns such as picky eating, food allergies, and childhood obesity. Our team works closely with parents and caregivers to create personalized nutrition plans that cater to the individual needs of each child, ensuring that they receive the essential nutrients required at every stage of development. We also provide education on fostering healthy eating habits and making informed dietary choices, setting the foundation for a lifetime of good health.",
    moreLongDescription: "The Pediatric Nutrition Management program provides a comprehensive approach to addressing the nutritional needs of children at various stages of growth. We begin with an in-depth assessment of your child’s health, dietary habits, and any specific concerns such as food allergies or obesity. Based on this assessment, we create a customized nutrition plan that supports healthy growth and development. Our program includes guidance on balanced meal planning, managing picky eating behaviors, and ensuring adequate intake of essential nutrients. We also offer education and support to parents and caregivers on fostering healthy eating habits and creating a positive mealtime environment. Regular follow-up visits allow us to monitor your child’s progress, make necessary adjustments to their nutrition plan, and address any emerging concerns. Our aim is to promote optimal health and well-being for your child through expert nutritional support and guidance.",
    image: "/assets/pediatric.png"
  },
  {
    id: 6,
    title: "Nutrition for Pregnancy, Lactation, and IVF",
    description: "Supporting mothers and expectant mothers with tailored nutrition plans for healthy pregnancy, successful breastfeeding, and IVF.",
    longDescription: "Our Nutrition for Pregnancy, Lactation, and IVF program is designed to support mothers and expectant mothers in receiving the essential nutrients needed for a healthy pregnancy and successful breastfeeding. We provide tailored meal plans that focus on promoting fetal development, maintaining maternal health, and enhancing the success rates of IVF treatments. Understanding the unique nutritional needs of women during these critical periods, we offer expert guidance on managing pregnancy-related concerns such as morning sickness, gestational diabetes, and weight management. Our program aims to empower women with the knowledge and confidence to nourish their bodies and their babies, ensuring a positive and healthy experience throughout pregnancy, lactation, and beyond.",
    moreLongDescription: "The Nutrition for Pregnancy, Lactation, and IVF program offers a thorough approach to supporting maternal and fetal health during pregnancy and breastfeeding, as well as enhancing the success of IVF treatments. We start with a comprehensive nutritional assessment to identify your specific needs and any potential challenges. Based on this assessment, we develop a customized meal plan that addresses key concerns such as adequate calorie and nutrient intake, managing pregnancy-related conditions, and supporting optimal fetal development. Our program includes education on managing common issues like morning sickness, iron deficiency, and gestational diabetes. We also provide guidance on maintaining a balanced diet during lactation to support both maternal and infant health. For those undergoing IVF, we offer tailored nutrition strategies to enhance reproductive health and improve treatment outcomes. Regular consultations ensure that we track your progress, make adjustments as needed, and provide ongoing support throughout your journey. Our goal is to help you achieve a healthy and successful pregnancy and breastfeeding experience while providing the best support for your IVF journey.",
    image: "/assets/IVF.png"
  },
  // {
  //   id: 7,
  //   title: "Therapeutic Nutrition",
  //   description: "Tailored dietary intervention for managing specific medical conditions like renal disease, hepatic disorders, COPD, and arthritis.",
  //   longDescription: "Our Therapeutic Nutrition service is tailored for individuals with specific medical conditions requiring specialized dietary intervention. Whether you are dealing with renal disease, hepatic disorders, chronic obstructive pulmonary disease (COPD), or arthritis, our program focuses on medical nutrition therapy to manage symptoms, support treatment, and improve overall quality of life. We provide personalized dietary plans that address the unique nutritional needs associated with your condition, working closely with your healthcare team to ensure a comprehensive approach to your care. Our goal is to help you manage your condition more effectively through nutrition, enhancing your well-being and supporting your journey towards better health.",
  //   moreLongDescription: "The Therapeutic Nutrition program offers a detailed and personalized approach to managing various medical conditions through specialized dietary interventions. We begin with an in-depth assessment of your medical history, current condition, and dietary habits. From this, we develop a customized nutrition plan that targets your specific needs and helps manage symptoms. Whether dealing with renal disease, hepatic disorders, COPD, or arthritis, our program focuses on optimizing your nutritional intake to support treatment goals and improve overall health. We provide ongoing education and support to help you understand the impact of different foods and nutrients on your condition. Regular follow-ups allow us to monitor your progress, make adjustments to your nutrition plan as necessary, and address any challenges you may face. Our aim is to enhance your quality of life through effective nutrition management tailored to your individual health needs.",
  //   image: "/assets/therapeutic.png"
  // },
  // {
  //   id: 8,
  //   title: "Therapeutic Nutrition",
  //   description: "Tailored dietary intervention for managing specific medical conditions like renal disease, hepatic disorders, COPD, and arthritis.",
  //   longDescription: "Our Therapeutic Nutrition service is tailored for individuals with specific medical conditions requiring specialized dietary intervention. Whether you are dealing with renal disease, hepatic disorders, chronic obstructive pulmonary disease (COPD), or arthritis, our program focuses on medical nutrition therapy to manage symptoms, support treatment, and improve overall quality of life. We provide personalized dietary plans that address the unique nutritional needs associated with your condition, working closely with your healthcare team to ensure a comprehensive approach to your care. Our goal is to help you manage your condition more effectively through nutrition, enhancing your well-being and supporting your journey towards better health.",
  //   moreLongDescription: "The Therapeutic Nutrition program offers a detailed and personalized approach to managing various medical conditions through specialized dietary interventions. We begin with an in-depth assessment of your medical history, current condition, and dietary habits. From this, we develop a customized nutrition plan that targets your specific needs and helps manage symptoms. Whether dealing with renal disease, hepatic disorders, COPD, or arthritis, our program focuses on optimizing your nutritional intake to support treatment goals and improve overall health. We provide ongoing education and support to help you understand the impact of different foods and nutrients on your condition. Regular follow-ups allow us to monitor your progress, make adjustments to your nutrition plan as necessary, and address any challenges you may face. Our aim is to enhance your quality of life through effective nutrition management tailored to your individual health needs.",
  //   image: "/assets/therapeutic.png"
  // },
  // {
  //   id: 9,
  //   title: "Therapeutic Nutrition",
  //   description: "Tailored dietary intervention for managing specific medical conditions like renal disease, hepatic disorders, COPD, and arthritis.",
  //   longDescription: "Our Therapeutic Nutrition service is tailored for individuals with specific medical conditions requiring specialized dietary intervention. Whether you are dealing with renal disease, hepatic disorders, chronic obstructive pulmonary disease (COPD), or arthritis, our program focuses on medical nutrition therapy to manage symptoms, support treatment, and improve overall quality of life. We provide personalized dietary plans that address the unique nutritional needs associated with your condition, working closely with your healthcare team to ensure a comprehensive approach to your care. Our goal is to help you manage your condition more effectively through nutrition, enhancing your well-being and supporting your journey towards better health.",
  //   moreLongDescription: "The Therapeutic Nutrition program offers a detailed and personalized approach to managing various medical conditions through specialized dietary interventions. We begin with an in-depth assessment of your medical history, current condition, and dietary habits. From this, we develop a customized nutrition plan that targets your specific needs and helps manage symptoms. Whether dealing with renal disease, hepatic disorders, COPD, or arthritis, our program focuses on optimizing your nutritional intake to support treatment goals and improve overall health. We provide ongoing education and support to help you understand the impact of different foods and nutrients on your condition. Regular follow-ups allow us to monitor your progress, make adjustments to your nutrition plan as necessary, and address any challenges you may face. Our aim is to enhance your quality of life through effective nutrition management tailored to your individual health needs.",
  //   image: "/assets/therapeutic.png"
  // }
];


export const approaches = [
  {
    title: 'Online Consultations',
    icon: faVideo,
    desc: 'No matter where you are, all our services are available online over video or telephone at your own convenience. Our approach is entirely personalized to you.',
  },
  {
    title: 'Individual Approach',
    icon: faUser,
    desc: 'You will never feel like a number in a huge indifferent system. Our approach is entirely personalized to you, committed to providing the best science-based information.',
  },
  {
    title: 'Evidence-Based',
    icon: faFlask,
    desc: 'Our approach is entirely personalized to you. We are committed to providing the best science-based information possible. We only advise you with the available evidence.',
  },
];


// Define the infoData with icon components
export const infoData = {
  image: "/path/to/author-image.jpg",
  name: "Sophie Moore",
  role: "Nutritionist",
  description: "Sophie shares her recipe picks, tips, and more. Join her to discover healthy meal ideas and nutrition advice.",
  socialLinks: [
    { name: "Facebook", url: "https://www.facebook.com", icon: <FaFacebook /> },
    { name: "Twitter", url: "https://www.twitter.com", icon: <FaTwitter /> },
    { name: "Instagram", url: "https://www.instagram.com", icon: <FaInstagram /> },
    { name: "LinkedIn", url: "https://www.linkedin.com", icon: <FaLinkedin /> }
  ],
  plans: [
    { name: "The Basic Plan", image: "/path/to/plan-image.jpg" }
  ]
};


export const contactInfo = {
  name: "Sweta Basu",
  email: "sweta.basu17@gmail.com",
  phone: "+918240755994",
  address: "23K Baderaipur Road, Shaktigarh, PO- Jadavpur, Kolkata-700032",
  lat: 22.4918235,
  lng: 88.3660878,
  api_key:"AIzaSyBVs0lXV1zrqo4zB8ebAVA4peEtRY4ssfQ",
  upi_end: "@okhdfcbank",
}

/*
[10:52 pm, 15/8/2024] Ashok Jiju: Email- dietwithsweta@gmail.com
[10:52 pm, 15/8/2024] Ashok Jiju: Phone- +91 8240755994
[10:52 pm, 15/8/2024] Ashok Jiju: Address- 
23K Baderaipur Road, 
Shaktigarh, PO- Jadavpur, Kolkata-700032
*/

